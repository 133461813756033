import React, { useState } from "react";
import { useContext } from "react";
import { AppContext, PageContext } from "../../lib/context";
import { Form, Input, Button, Spin } from "antd";
import { useEffect } from "react";
import { useMemo } from "react";

const DashboardView = (props) => {
  const { data, loading, bm_estimates, allocations_data } = useContext(
    PageContext
  );
  const { IS_ADMIN } = useContext(AppContext);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("portal_user_info"))
  );

  useMemo(() => {
    let user_info = localStorage.getItem("portal_user_info");
    setUserInfo(JSON.parse(user_info));
  }, [data]);

  if (loading)
    return (
      <div className="flex h-full">
        <Spin className="self-center" />
      </div>
    );

  return (
    <div className="flex flex-col h-screen items-center pt-32">
      <p className="text-2xl mb-10">Dashboard</p>
      <div className="flex flex-row justify-between flex-wrap">
        <div className="border border-green-200 px-12 py-8 text-xl text-green-400 shadow-lg rounded-lg mr-5">
          <p>
            {data?.pending?.aggregate?.count +
              data?.accepted?.aggregate?.count +
              data?.screened?.aggregate?.count +
              data?.approved?.aggregate?.count}
          </p>
          <p>TOTAL</p>
        </div>
        <div className="border border-green-200 px-12 py-8 text-xl text-green-400 shadow-lg rounded-lg mr-5">
          <p>{data?.pending?.aggregate?.count}</p>
          <p>PENDING</p>
        </div>
        <div className="border border-green-200 px-12 py-8 text-xl text-green-400 shadow-lg rounded-lg mr-5">
          <p>{data?.accepted?.aggregate?.count}</p>
          <p>ACCEPTED</p>
        </div>
        <div className="border border-green-200 px-12 py-8 text-xl text-green-400 shadow-lg rounded-lg mr-5">
          <p>{data?.screened?.aggregate?.count}</p>
          <p>SCREENED</p>
        </div>
        <div className="border border-green-200 px-12 py-8 text-xl text-green-400 shadow-lg rounded-lg">
          <p>{data?.approved?.aggregate?.count}</p>
          <p>APPROVED</p>
        </div>
      </div>
      <div className="border border-green-200 mt-5 w-3/5 py-2 shadow-lg flex flex-row">
        <div className="w-2/5">
          <p className="text-xl">Total Amount</p>
        </div>
        <div className="w-1/5 flex flex-col">
          <span className="text-green-400 text-base">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "php",
            }).format(
              allocations_data?.cat_a_pending?.aggregate?.count * 50000 +
                allocations_data?.cat_b_pending?.aggregate?.count * 20000 +
                allocations_data?.cat_c_pending?.aggregate?.count * 10000 +
                allocations_data?.cat_a_accept?.aggregate?.count * 50000 +
                allocations_data?.cat_b_accept?.aggregate?.count * 20000 +
                allocations_data?.cat_c_accept?.aggregate?.count * 10000
            )}
          </span>
          <span className="text-blue-400 text-base">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "php",
            }).format(
              IS_ADMIN
                ? 100000000
                : userInfo?.username === "bm.vgo"
                ? 15000000
                : 5000000
            )}
          </span>
        </div>
        <div className="flex flex-col w-1/5">
          <span className="text-green-400 text-3xl">
            {(
              ((allocations_data?.cat_a_pending?.aggregate?.count * 50000 +
                allocations_data?.cat_b_pending?.aggregate?.count * 20000 +
                allocations_data?.cat_c_pending?.aggregate?.count * 10000 +
                allocations_data?.cat_a_accept?.aggregate?.count * 50000 +
                allocations_data?.cat_b_accept?.aggregate?.count * 20000 +
                allocations_data?.cat_c_accept?.aggregate?.count * 10000) /
                (IS_ADMIN
                  ? 100000000
                  : userInfo?.username === "bm.vgo"
                  ? 15000000
                  : 5000000)) *
              100
            ).toFixed(2)}{" "}
            %
          </span>
        </div>
      </div>
      <div className="border text-center border-green-200 mt-5 py-2 w-3/5 px-32 shadow-lg flex flex-col">
        <div className="flex flex-row justify-evenly">
          <div className="w-1/4 font-bold"><p>Category</p></div>
          <div className="w-1/4 font-bold"><p>Applications</p></div>
          <div className="w-1/4 font-bold"><p>Allocations</p></div>
          <div className="w-1/4 font-bold"><p>Total Amount</p></div>
        </div>
        <div className="flex flex-row justify-evenly">
          <div className="w-1/4"><p>A</p></div>
          <div className="w-1/4"><p className="text-base">
            {allocations_data?.cat_a_pending?.aggregate?.count +
              allocations_data?.cat_a_accept?.aggregate?.count}
          </p></div>
          <div className="w-1/4"><p>PHP 50,000.00</p></div>
          <div className="w-1/4"><p className="text-base text-blue-400">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "php",
            }).format(
              (allocations_data?.cat_a_pending?.aggregate?.count +
                allocations_data?.cat_a_accept?.aggregate?.count) *
                50000
            )}
          </p></div>
        </div>
        <div className="flex flex-row justify-evenly">
          <div className="w-1/4"><p>B</p></div>
          <div className="w-1/4"><p className="text-base">
            {allocations_data?.cat_b_pending?.aggregate?.count +
              allocations_data?.cat_b_accept?.aggregate?.count}
          </p></div>
          <div className="w-1/4"><p>PHP 20,000.00</p></div>
          <div className="w-1/4"><p className="text-base text-blue-400">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "php",
            }).format(
              (allocations_data?.cat_b_pending?.aggregate?.count +
                allocations_data?.cat_b_accept?.aggregate?.count) *
                20000
            )}
          </p></div>
        </div>
        <div className="flex flex-row justify-evenly">
          <div className="w-1/4"><p>C</p></div>
          <div className="w-1/4"><p className="text-base">
            {allocations_data?.cat_c_pending?.aggregate?.count +
              allocations_data?.cat_c_accept?.aggregate?.count}
          </p></div>
          <div className="w-1/4"><p>PHP 10,000.00</p></div>
          <div className="w-1/4"><p className="text-base text-blue-400">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "php",
            }).format(
              (allocations_data?.cat_c_pending?.aggregate?.count +
                allocations_data?.cat_c_accept?.aggregate?.count) *
                10000
            )}
          </p></div>
        </div>
        <div className="flex flex-row justify-end">
          <div className="flex flex-col">
            <p className="text-xl text-green-400">
              Total: {' '}
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "php",
              }).format(
                allocations_data?.cat_a_pending?.aggregate?.count * 50000 +
                  allocations_data?.cat_b_pending?.aggregate?.count * 20000 +
                  allocations_data?.cat_c_pending?.aggregate?.count * 10000 +
                  allocations_data?.cat_a_accept?.aggregate?.count * 50000 +
                  allocations_data?.cat_b_accept?.aggregate?.count * 20000 +
                  allocations_data?.cat_c_accept?.aggregate?.count * 10000
              )}
            </p>
          </div>
        </div>
      </div>
      <p className="mt-20 mb-5">
        Estimates for each criterias. District Total Allocation is PHP
        {userInfo?.username === "bm.vgo" ? " 15,000,000" : " 5,000,000"}
      </p>
      <div className="border border-green-200 w-3/5 px-12 py-2 mt-5 text-base text-gray-600 shadow-lg rounded-lg">
        <p>
          Pending Allocation Amount:{" "}
          <span className="text-green-400">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "php",
            }).format(
              allocations_data?.cat_a_pending?.aggregate?.count * 50000 +
                allocations_data?.cat_b_pending?.aggregate?.count * 20000 +
                allocations_data?.cat_c_pending?.aggregate?.count * 10000
            )}
          </span>
        </p>
      </div>
      <div className="border border-green-200 w-3/5 px-12 py-2 mt-5 text-base text-gray-600 shadow-lg rounded-lg">
        <p>
          Accepted Allocation Amount:{" "}
          <span className="text-green-400">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "php",
            }).format(
              allocations_data?.cat_a_accept?.aggregate?.count * 50000 +
                allocations_data?.cat_b_accept?.aggregate?.count * 20000 +
                allocations_data?.cat_c_accept?.aggregate?.count * 10000
            )}
          </span>
        </p>
      </div>
      <div className="border border-green-200 w-3/5 px-12 py-2 mt-5 text-base text-gray-600 shadow-lg rounded-lg">
        <p>
          Approved Allocation Amount:{" "}
          <span className="text-green-400">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "php",
            }).format(
              allocations_data?.cat_a_approved?.aggregate?.count * 50000 +
                allocations_data?.cat_b_approved?.aggregate?.count * 20000 +
                allocations_data?.cat_c_approved?.aggregate?.count * 10000
            )}
          </span>
        </p>
      </div>
      <div className="border border-green-200 w-3/5 px-12 py-2 mt-5 text-base text-gray-600 shadow-lg rounded-lg">
        <p>
          Unprocessed Allocation Amount:{" "}
          <span className="text-green-400">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "php",
            }).format(
              allocations_data?.cat_a_pending?.aggregate?.count * 50000 +
                allocations_data?.cat_b_pending?.aggregate?.count * 20000 +
                allocations_data?.cat_c_pending?.aggregate?.count * 10000 +
                allocations_data?.cat_a_accept?.aggregate?.count * 50000 +
                allocations_data?.cat_b_accept?.aggregate?.count * 20000 +
                allocations_data?.cat_c_accept?.aggregate?.count * 10000
            )}
          </span>
        </p>
      </div>
    </div>
  );
};

export default DashboardView;
