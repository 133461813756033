import _ from "lodash";
import axios from "axios";

export const formatMoney = (x) => {
  return x?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getResultAggregates = (result) => {
  const red2 = _.filter(result, { result: "2_REDS" }).length;
  const red3 = _.filter(result, { result: "3_REDS" }).length;
  const white2 = _.filter(result, { result: "2_WHITES" }).length;
  const white3 = _.filter(result, { result: "3_WHITES" }).length;
  const star1 = _.filter(result, { result: "ANY_STAR" }).length;
  const star2 = _.filter(result, { result: "TWO_STARS" }).length;
  return {
    red2,
    red3,
    white2,
    white3,
    star1,
    star2,
  };
};

export const getGameStatusIndex = (status) => {
  switch (status) {
    case "OPEN_BET":
    case "ROUND_START":
      return 0;
    case "ROUND_DONE":
      return 1;
    case "SHOW_RESULTS":
      return 5;
    case "GAME_STOPPED":
      return 7;
    default:
      return 8;
      break;
  }
};

export const uploadFile = (file) => {
  let formData = new FormData();
  formData.append("file", file);
  return axios
    .post("https://vehicle-api.wetrace.ph/upload", formData, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_DEFAULT_JWT}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getSignedImageUrl = async (key) => {
  let url = "";
  if (key?.length > 1) {
    key = key.replace("https://pasajob.s3.amazonaws.com/", "");
    let image = await axios.get("https://auth.pasajob.com/s3-signed-url", {
      params: {
        bucket: "pasajob",
        key,
      },
    });
    url = image.data;
    return url;
  } else {
    return url;
  }
};
