import React from "react";
import { useContext } from "react";
import { AppContext, PageContext } from "../../lib/context";
import { columns } from "./components/table-columns";
import { Offices } from "../../lib/offices";
import { Button, Input, Select, Space, Spin, Table, Tag } from "antd";
import { useState } from "react";
import { useRef } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { CSVLink, CSVDownload } from "react-csv";

const DashboardViews = (props) => {
  const { registrations, onOfficeChange, csvCpgData, loading_user_registrations } = useContext(PageContext);
  const { IS_ADMIN } = useContext(AppContext);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      return dataIndex === "name"
        ? (record["first_name"] + " " + record["last_name"])
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  return (
    <div className="mt-10">
      <p className="text-2xl">Approved User Registrations</p>
      <div>
        {IS_ADMIN && (
          <Select className="w-2/5 my-3" onChange={onOfficeChange}>
            {Offices.map((office, key) => (
              <Select.Option value={office.OFFICE}>
                {office.OFFICE}
              </Select.Option>
            ))}
          </Select>
        )}
        <br/><br/>
        <CSVLink data={csvCpgData}>
          <Button type="primary" className="mb-2" disabled={loading_user_registrations}>
            {loading_user_registrations ? <Spin /> : "Download CSV"}
          </Button>
        </CSVLink>
        <Table
          columns={[
            {
              title: "Name",
              key: "name",
              ...getColumnSearchProps("name"),
              render: (text) => (
                <a href={"/show-details/" + text.id}>
                  {text.first_name} {text.last_name}
                </a>
              ),
            },
            {
              title: "Email",
              dataIndex: "email",
              key: "email",
              ...getColumnSearchProps("email"),
            },
            {
              title: "Mobile number",
              dataIndex: "mobile_number",
              key: "mobile_number",
              ...getColumnSearchProps("mobile_number"),
            },
            {
              title: "Gender",
              dataIndex: "gender",
              key: "gender",
            },
            {
              title: "Category",
              dataIndex: "category",
              key: "category",
              render: (category) => (
                <>
                  <Tag
                    color={`${
                      category === "a"
                        ? "blue"
                        : category === "b"
                        ? "green"
                        : "red"
                    }`}
                    key={category}
                  >
                    {category === "a"
                      ? "NEGOSYONG PADAYONON CATEGORY"
                      : category === "b"
                      ? "PRODUKTONG SUGBOANON CATEGORY"
                      : "MGA SERBISYO UG GINAGMAYNG PATIGAYON CATEGORY"}
                  </Tag>
                </>
              ),
            },
            {
              title: "Business Name",
              key: "new_business_name",
              dataIndex: "new_business_name",
              render: (new_business_name) => (
                <>
                  <Tag color="green" key={new_business_name}>
                    {new_business_name}
                  </Tag>
                </>
              ),
            },
            {
              title: "Status",
              key: "status",
              dataIndex: "status",
              render: (status) => (
                <>
                  <Tag
                    color={
                      status === "pending"
                        ? "geekblue"
                        : status === "approved"
                        ? "gold"
                        : "red"
                    }
                    key={status}
                  >
                    {status}
                  </Tag>
                </>
              ),
            },
          ]}
          dataSource={registrations}
        />
      </div>
    </div>
  );
};

export default DashboardViews;
