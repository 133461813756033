export const item_columns = [
  {
    title: "Item",
    key: "item",
    dataIndex: "item"
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "Cost",
    dataIndex: "cost",
    key: "cost",
  },
  {
    title: "Unit price",
    dataIndex: "unit_price",
    key: "unit_price",
  },
  {
    title: "Suggested Location vendor",
    dataIndex: "suggested_supplier_vendor",
    key: "suggested_supplier_vendor",
  },
];


export const certificates_column = [
  {
    title: "Certificate name",
    key: "name",
    dataIndex: "name"
  },
];

export const item_columns_a = [
  {
    title: "Item",
    key: "item",
    dataIndex: "item"
  },
  {
    title: "Unit price",
    dataIndex: "unit_price",
    key: "unit_price",
  },
];