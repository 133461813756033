import React, { useState } from "react";
import { Input, Form, Button, notification, message } from "antd";
import DashboardView from "./view";
import { PageContext } from "../../lib/context";
import { ADMIN_BM_ESTIMATES, AGGREGATES, ALLOCATIONS } from "../../lib/query";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useMemo } from "react";
import _ from "lodash";

const DashboardController = (props) => {
  const [allocations, setAllocations] = useState({
    pending: 0,
    accepted: 0,
    approved: 0,
    unproccessed: 0,
  });

  const { data, loading } = useQuery(AGGREGATES);

  const { data: bm_estimates } = useQuery(ADMIN_BM_ESTIMATES)

  const { data: allocations_data } = useQuery(ALLOCATIONS);

  const pageValues = {
    data,
    loading,
    allocations,
    bm_estimates,
    allocations_data
  };

  return (
    <PageContext.Provider value={pageValues}>
      <DashboardView />
    </PageContext.Provider>
  );
};

export default DashboardController;
