import React, { useMemo, useState, useEffect } from "react";
import { PageContext } from "../../lib/context";
import ShowDetailsView from "./view";
import { useParams } from "react-router-dom";
import { USER_DETAILS } from "../../lib/query";
import { Form, notification, Button } from "antd";
import { useQuery, useMutation } from "@apollo/react-hooks";
import moment from "moment";
import {
  UPDATE_CATEGORY,
  UPDATE_USER,
  UPDATE_USER_INFO,
} from "../../lib/mutations";
import S3 from "react-aws-s3";
import { getSignedImageUrl, uploadFile } from "../../lib/util";

const ShowDetails = (props) => {
  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: process.env.REACT_APP_DIR_NAME /* optional */,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY_ACCESS,
    s3Url: process.env.REACT_APP_S3_URL,
  };
  const S3Client = new S3(config);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const { id } = useParams();

  const [registrations, setRegistrations] = useState(null);
  const [items, setItems] = useState(null);
  const [certificates, setCertificates] = useState(null);
  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState(null);
  const [category, setCategory] = useState(null);
  const [updatingPicture, setUpdatingPicture] = useState(false);
  const [picture, setPicture] = useState(null);
  const [user_id_picture, setUserIdPicture] = useState(null);

  useMemo(() => {
    if (registrations?.user_picture) {
      async function getImage() {
        let user_pic = await getSignedImageUrl(registrations?.user_picture);
        let user_id = await getSignedImageUrl(registrations?.user_id_picture);
        setPicture(user_pic);
        setUserIdPicture(user_id);
      }
      getImage();
    }
  }, [registrations]);

  const {
    data: user_registrations,
    loading: loading_user_registrations,
  } = useQuery(USER_DETAILS, {
    variables: {
      id,
    },
  });

  const [updateUser, { loading: updating_user }] = useMutation(UPDATE_USER);
  const [updateCategory, { loading: updating_category }] = useMutation(
    UPDATE_CATEGORY
  );
  const [updateUserInfo, { loading: updating_user_info }] = useMutation(
    UPDATE_USER_INFO
  );

  useEffect(() => {
    if (user_registrations) {
      const user = user_registrations?.users?.[0];
      form.setFieldsValue({
        ...user,
        birth_date: moment(user?.birth_date),
        spouse_birth_date: moment(user?.spouse_birth_date),
        id_expiry: moment(user?.id_expiry),
        receive_date: moment(user?.receive_date),
      });
      setRegistrations(user);
      setItems(user?.user_items);
      setCertificates(user?.user_business_certificates);

      if (!user_registrations?.users?.length) {
        window.location.href = "/dashboard";
      }
    }
  }, [user_registrations]);

  const handleRegistration = (status) => {
    setStatus(status);
    setVisible(true);
  };

  const handleFormFinish = async (data) => {
    if (status !== "deny") {
      let res = await updateUser({
        variables: {
          wallet_balance: parseFloat(registrations?.wallet_balance),
          status: status,
          id,
        },
      });
      if (res?.data?.update_users?.affected_rows) {
        notification.success({
          message: "Registration has been " + status,
        });
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 1000);
      } else {
        notification.error({
          message: "Failed to approve",
        });
      }
    } else {
      let res = await updateUser({
        variables: {
          wallet_balance: parseFloat(0),
          status: "denied",
          id,
        },
      });
      if (res?.data?.update_users?.affected_rows) {
        notification.success({
          message: "Registration has been denied",
        });
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 1000);
      } else {
        notification.error({
          message: "Failed to denied",
        });
      }
    }
  };

  const renderAcceptApproveScreenButton = (status, btntext) => {
    return (
      <Button
        disabled={updating_user || updating_user_info || updating_category}
        type="primary"
        className="text-center mr-2"
        onClick={() => handleRegistration(status)}
      >
        {btntext}
      </Button>
    );
  };

  const renderDenyButton = () => {
    return (
      <Button
        disabled={updating_user || updating_user_info || updating_category}
        type="primary"
        className="text-center bg-red-400 border-red-400"
        onClick={() => handleRegistration("deny")}
      >
        Deny
      </Button>
    );
  };

  const renderButtons = () => {
    const user_info = JSON.parse(localStorage.getItem("portal_user_info"));
    if (user_info?.permission === "bm") {
      switch (registrations?.status) {
        case "pending":
          return (
            <>
              {renderAcceptApproveScreenButton("accept", "Accept")}
              {renderDenyButton()}
            </>
          );

        case "accept":
          return <>{renderDenyButton()}</>;

        default:
          return <></>;
      }
    }

    if (user_info?.permission === "chamber") {
      switch (registrations?.status) {
        case "accept":
          return (
            <>
              {renderAcceptApproveScreenButton("screened", "Screen")}
              {renderDenyButton()}
            </>
          );

        case "screened":
          return (
            <>
              {renderAcceptApproveScreenButton("approved", "Approve")}
              {renderDenyButton()}
            </>
          );

        default:
          return <></>;
      }
    }

    if (user_info?.permission === "committee") {
      switch (registrations?.status) {
        case "approved":
          return (
            <>
              {renderAcceptApproveScreenButton("passed", "Pass application")}
              {renderDenyButton()}
            </>
          );
        case "denied":
          return (
            <>{renderAcceptApproveScreenButton("passed", "Pass application")}</>
          );

        default:
          return <></>;
      }
    }
  };

  const handleSaveCategory = async () => {
    if (!id || !category) return;
    const res = await updateCategory({
      variables: {
        id,
        category,
      },
    });
    if (res?.data?.update_users?.affected_rows > 0) {
      notification.success({
        message: "Successfully updated category",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      notification.error({
        message: "Failed to update category",
      });
    }
  };

  const handleSave = async (data) => {
    const res = await updateUserInfo({
      variables: {
        id,
        data: { ...data },
      },
    });
    if (res?.data?.update_users?.affected_rows) {
      notification.success({
        message: "Successfully updated info",
      });
      setTimeout(() => {
        window.location.reload();
      }, 400);
    } else {
      notification.error({
        message: "Failed to update user info",
      });
    }
  };

  const handleSetToPending = async () => {
    let res = await updateUser({
      variables: {
        wallet_balance: parseFloat(0),
        status: "pending",
        id,
      },
    });
    if (res?.data?.update_users?.affected_rows) {
      notification.success({
        message: "Registration has been set to PENDING",
      });
      setTimeout(() => {
        window.location.href = "/denied";
      }, 1000);
    } else {
      notification.error({
        message: "Failed to set to pending",
      });
    }
  };

  const handleSetToDenied = async () => {
    let res = await updateUser({
      variables: {
        wallet_balance: parseFloat(0),
        status: "denied",
        id,
      },
    });
    if (res?.data?.update_users?.affected_rows) {
      notification.success({
        message: "Registration has been set to DENIED",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      notification.error({
        message: "Failed to set to denied",
      });
    }
  };

  const getCategory = (category) => {
    const categories = {
      a: "NEGOSYONG PADAYONON CATEGORY",
      b: "PRODUKTONG SUGBOANON CATEGORY",
      c: "MGA SERBISYO UG GINAGMAYNG PATIGAYON CATEGORY",
    };
    return categories[category];
  };

  const handleUserImageChange = async (file) => {
    setUpdatingPicture(true);
    // const { location: user_picture } = await S3Client.uploadFile(
    //   file,
    //   "pic-" + moment().format("YYYY-MM-DD h:m:s").toString()
    // );
    const { data: user_picture } = await uploadFile(file);
    //setUpdatingPicture(false);
    //console.log("pic", user_picture);
    //return;
    const res = await updateUserInfo({
      variables: {
        id,
        data: {
          user_picture,
        },
      },
    });
    if (res?.data?.update_users?.affected_rows) {
      notification.success({
        message: "Successfully updated photo",
      });
      setUpdatingPicture(false);
      setTimeout(() => {
        window.location.reload();
      }, 400);
    } else {
      setUpdatingPicture(false);
      notification.error({
        message: "Failed to update user photo",
      });
    }
  };

  const pageValues = {
    form,
    items,
    certificates,
    visible,
    setVisible,
    status,
    handleRegistration,
    form2,
    handleFormFinish,
    registrations,
    renderButtons,
    category,
    setCategory,
    handleSaveCategory,
    updating_category,
    handleSave,
    updating_user_info,
    handleSetToPending,
    getCategory,
    handleSetToDenied,
    handleUserImageChange,
    updatingPicture,
    picture,
    user_id_picture,
  };
  return (
    <PageContext.Provider value={pageValues}>
      <ShowDetailsView />
    </PageContext.Provider>
  );
};

export default ShowDetails;
