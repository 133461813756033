import React, { useContext } from "react";
import {
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Radio,
  Checkbox,
  Button,
  Space,
  Table,
  Spin,
} from "antd";
import {
  UserOutlined,
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { AppContext, PageContext } from "../../lib/context";
import { item_columns, certificates_column } from "./table-columns";
import Modal from "antd/lib/modal/Modal";
import FormA from "./components/form-a";
import FormB from "./components/form-b";
import FormC from "./components/form-c";

const ShowDetailsView = (props) => {
  const {
    form,
    handleClassificationChange,
    classification,
    items,
    certificates,
    visible,
    setVisible,
    handleRegistration,
    status,
    form2,
    handleFormFinish,
    registrations,
    category,
    setCategory,
    handleSaveCategory,
    updating_category,
  } = useContext(PageContext);

  const { IS_ADMIN } = useContext(AppContext);

  const required = (name) => {
    return [
      {
        required: true,
        message: `${name} is required`,
      },
    ];
  };

  return (
    <div className="mb-56 px-56">
      <p className="text-xl font-bold">Registration Form</p>
      {registrations?.category === "a" && <FormA />}
      {registrations?.category === "b" && <FormB />}
      {registrations?.category === "c" && <FormC />}
      {IS_ADMIN && (
        <div className="mt-10 flex flex-col">
          <Select
            value={category??registrations?.category}
            onChange={(value) => setCategory(value)}
          >
            <Select.Option value="a">
              A - NEGOSYONG PADAYONON CATEGORY
            </Select.Option>
            <Select.Option value="b">
              B - PRODUKTONG SUGBOANON CATEGORY
            </Select.Option>
            <Select.Option value="c">
              C - MGA SERBISYO UG GINAGMAYNG PATIGAYON CATEGORY
            </Select.Option>
          </Select>
          <Button
            disabled={category === null || updating_category}
            className="mt-5"
            type="primary"
            onClick={handleSaveCategory}
          >
            {updating_category ? <Spin /> : "Change Category"}
          </Button>
        </div>
      )}

      <Modal
        title={`${status} registration`}
        visible={visible}
        onCancel={() => setVisible(false)}
        okButtonProps={{ style: { display: "none" } }}
      >
        {status === "approve" && (
          <div>
            <Form layout="vertical" form={form2} onFinish={handleFormFinish}>
              <Form.Item
                name="wallet_balance"
                label="Set wallet balance"
                required
                rules={required("Wallet balance")}
              >
                <InputNumber
                  className="w-full"
                  placeholder="Enter wallet balance to allocate e.g. 5000"
                />
              </Form.Item>
              <Button type="primary" htmlType="submit">
                Approve
              </Button>
            </Form>
          </div>
        )}
        {status !== "deny" && (
          <div>
            <Form layout="vertical" form={form2} onFinish={handleFormFinish}>
              <Button type="primary" htmlType="submit">
                {status === "pending" && "Accept"}
                {status === "accept" && "Set as Accepted"}
                {status === "screened" && "Set as Screened"}
                {status === "approved" && "Set as Approved"}
                {status === "passed" && "Set as Passed"}
              </Button>
            </Form>
          </div>
        )}
        {status === "deny" && (
          <div>
            <p>Are you sure you want to deny this registration?</p>
            <Form layout="vertical" form={form2} onFinish={handleFormFinish}>
              <Button type="primary" htmlType="submit">
                Deny
              </Button>
            </Form>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ShowDetailsView;
