import React, { useCallback, useState } from "react";
import { Input, Form, Button, notification, message } from "antd";
import DashboardView from "./view";
import { PageContext } from "../../lib/context";
import {
  ADMIN_BM_ESTIMATES,
  AGGREGATES,
  ALLOCATIONS,
  USERS_SEARCH,
} from "../../lib/query";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useMemo } from "react";
import _ from "lodash";
import { getSignedImageUrl } from "../../lib/util";

const PrintController = (props) => {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [value, setValue] = useState("");
  const [search, setSearch] = useState("");
  const searchTextDebouncer = useCallback(_.debounce(setSearch, 500), []);

  const { data, loading } = useQuery(USERS_SEARCH, {
    variables: {
      search,
    },
  });

  useMemo(() => {
    if (data && !loading) {
      console.log("data", data);
      if (data?.users?.length) {
        const array = [];
        data.users.map((user, key) => {
          let temp = {
            label: `${user?.first_name?.toUpperCase()} ${user?.last_name?.toUpperCase()}`,
            value: JSON.stringify(user),
          };
          array.push(temp);
          if (key === data?.users?.length - 1) {
            console.log("arr", array);
            setOptions([...array]);
          }
        });
      }
    }
  }, [data]);

  const onSearch = (searchText) => {
    searchTextDebouncer("%" + searchText + "%");
    setValue(searchText);
  };

  const onSelect = async (data) => {
    console.log("data--", data);
    let parsed = JSON.parse(data);
    let user_picture = await getSignedImageUrl(parsed.user_picture);
    console.log("user picture", user_picture);
    setSelected([...selected, { ...parsed, user_picture }]);
    setValue(null);
  };

  const handleDelete = (key) => {
    setSelected(
      selected.filter(function (item, index) {
        return index !== key;
      })
    );
  };

  const pageValues = {
    data,
    loading,
    options,
    onSearch,
    selected,
    setSelected,
    onSelect,
    value,
    setValue,
    handleDelete,
  };

  return (
    <PageContext.Provider value={pageValues}>
      <DashboardView />
    </PageContext.Provider>
  );
};

export default PrintController;
